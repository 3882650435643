<template>
  <div class="forms">
    <v-toolbar flat>
      <v-toolbar-title>Nuevo Formulario</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form" @submit="submit" class="new__forms__form ma-5">
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field outlined :rules="rules.issueTypeName" label="*Nombre" class="mt-2" style="max-width:420px"
              v-model="name" :error-messages="nameError" @keydown="
                nameError = null
                error = null
              "></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-text-field style="max-width:420px" outlined name="description" label="Descripción" v-model="description">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="pa-0">
            <v-select style="max-width:420px" v-model="mutableField" outlined @change="onFieldChange" item-value="id"
              item-text="name" :items="notSelected" label="Agregar campo"></v-select>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-2" v-for="field in selectedFields" :key="field.id">
          <v-col cols="12">
            <v-row>
              <v-col cols="7" class="primary">
                <span class="white--text">
                  {{ field.name
                  }} ({{field.fieldType.name}})<v-btn small style="float:right" color="white" icon
                    @click="removeField(field.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-col>
              <v-col cols="2">
                <v-checkbox dense hide-details class="ml-8 mt-0" label="Obligatorio" color="primary"
                  v-model="field.required"></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-btn small rounded outlined color="primary" @click="addNestedQuestion(field)"
                  :disabled="isNestedDisabled(field)">Anidar pregunta</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="hasLogic(field)">
            <v-row class="grey lighten-4">
              <v-col cols="4">Pregunta</v-col>
              <v-col cols="2">Condición</v-col>
              <v-col cols="2">Valor</v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <v-row v-for="(logic, index) in logics" :key="index">
              <template v-if="logic.from === field.id">
                <v-col cols="4">
                  <v-select style="max-width:420px" v-model="logic.to" outlined dense item-value="id" item-text="name"
                    :items="notSelectedCurrent(logic.to, logic.rootPatherFieldId)"></v-select>
                </v-col>
                <v-col cols="2">
                  <template v-if="field.fieldType.slug === 'number'">
                    <v-select item-value="id" item-text="name" :items="conditionsNumbers" v-model="logic.condition" outlined
                      dense hide-details></v-select>
                  </template>
                  <template v-else>
                    <v-select item-value="id" item-text="name" :items="conditions" v-model="logic.condition" outlined
                      dense hide-details></v-select>
                  </template>
                </v-col>
                <v-col cols="2">
                  <template v-if="field.fieldType.slug === 'yes_no'">
                    <v-select :items="yesNoOptions" v-model="logic.value" outlined dense>
                    </v-select>
                  </template>
                  <template v-else>
                    <v-text-field dense outlined v-model="logic.value"></v-text-field>
                  </template>
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="removeNestedQuestion(logic, field)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="max-width:380px">
          <v-col>
            <v-btn rounded outlined block color="primary" class="mt-5" :to="{ name: 'forms' }">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn rounded :loading="isLoading" type="submit" block color="primary" class="mt-5" :disabled="!isValid">
              Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import rules from '@/constants/input-validation'
import { to } from '@/helpers'
import { FIELDS_GET_FIELDS, FORMS_CREATE_FORM } from '@/store/actions.type'
import { mapGetters } from 'vuex'

import { errorMessage } from '@/mixins'
export default {
  name: 'FormNew',
  created() {
    this.fetchFields()
  },
  data() {
    return {
      rules,
      selectedFields: [],
      fields: [],
      logics: [],
      mutableField: null,
      isValid: false,
      isLoading: false,
      description: '',
      descriptionError: '',
      name: '',
      nameError: '',
      error: null,
      conditions: [
        {
          id: 'equal',
          name: 'Igual'
        }
      ],
      conditionsNumbers: [
        {
          id: 'equal',
          name: 'Igual'
        },
        {
          id: 'not_equal',
          name: 'Diferente'
        },
        {
          id: 'lower_than',
          name: 'Menor'
        },
        {
          id: 'lower_equal_than',
          name: 'Menor o Igual'
        },
        {
          id: 'greater_than',
          name: 'Mayor'
        },
        {
          id: 'greater_equal_than',
          name: 'Mayor o Igual'
        }
      ],
      yesNoOptions: [
        {
          value: true,
          text: 'Si'
        }, {
          value: false,
          text: 'No'
        }
      ]
    }
  },
  mixins: [errorMessage],
  computed: {
    ...mapGetters(['getFields']),
    notSelected() {
      const selectedIds = this.selectedFields.map(elem => elem.id)
      const logicIds = this.logics.map(elem => elem.to)
      const mergedIds = [...selectedIds, ...logicIds]
      const notSelected = this.getFields.filter(elem => mergedIds.indexOf(elem.id) === -1)
      return notSelected
    }
  },
  methods: {
    isNestedDisabled(field) {
      const enabledTypes = ['short_text', 'number', 'long_text', 'yes_no', 'dropdown']
      if (enabledTypes.includes(field.fieldType.slug)) {
        return false
      } else {
        return true
      }
    },
    notSelectedCurrent(currentId, rootPatherFieldId) {
      const selectedIds = this.selectedFields.map(elem => elem.id)
      const logicIds = this.logics.map(elem => {
        if (elem.to && elem.rootPatherFieldId === rootPatherFieldId) { return elem.to }
      })
      const mergedIds = [...selectedIds, ...logicIds]

      const notSelected = this.getFields.filter(elem => mergedIds.indexOf(elem.id) === -1 || elem.id === currentId)
      return notSelected
    },
    getFieldName(id) {
      return this.getFields.filter(elem => elem.id === id)[0].name
    },
    hasLogic(field) {
      const byFrom = this.logics.filter(elem => elem.from === field.id)
      if (byFrom.length > 0) {
        return true
      } else {
        return false
      }
    },
    addNestedQuestion(field) {
      this.logics.push({ from: field.id, to: null, condition: null, value: '', rootPatherFieldId: field.id })
    },
    removeNestedQuestion(logic, field) {
      const logicIndex = this.logics.findIndex(elem => elem.to === logic.to && elem.rootPatherFieldId === field.id)
      this.logics.splice(logicIndex, 1)
    },
    async fetchFields() {
      this.isLoading = true
      const [err] = await to(this.$store.dispatch(FIELDS_GET_FIELDS))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    onFieldChange(fieldId) {
      this.selectField(fieldId)
      this.$nextTick(() => {
        this.mutableField = null
      })
    },
    selectField(fieldId) {
      const selected = this.getFields.find(elem => elem.id === fieldId)
      this.selectedFields.push(selected)
    },
    removeField(fieldId) {
      const selected = this.selectedFields.filter(elem => elem.id !== fieldId)
      this.selectedFields = selected
    },
    async submit(evt) {
      evt.preventDefault()
      const logicFields = this.logics.map(elem => {
        return { id: elem.to, isHidden: true }
      })

      const form = {
        name: this.name,
        description: this.description,
        fields: [...this.selectedFields, ...logicFields],
        logic: this.logics
      }
      this.isLoading = true
      this.error = null
      const [err] = await to(this.$store.dispatch(FORMS_CREATE_FORM, form))
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.nameError = this.errorMessage(err, 'name')
          this.descriptionError = this.errorMessage(err, 'description')
        }
      } else {
        this.$router.push({ name: 'forms' })
      }
    }
  }
}
</script>
